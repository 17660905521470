var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-60"},[(_vm.loading)?_c('loader-component'):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"c-card"},[_c('div',{staticClass:"filter-section"},[_c('form',[_c('ul',{staticClass:"filter-list"},[_c('li',[_c('b-button',{staticClass:"coll-btn",on:{"click":function($event){return _vm.selectFilter('Month')}}},[_vm._v(_vm._s(_vm.filter.quarter ? "Q" + _vm.filter.quarter : _vm.filter.month ? _vm.filter.month : "Month")+" "+_vm._s(_vm.getYearLabel(_vm.filter.year))+" "),_c('i',{staticClass:"fas fa-chevron-down"})])],1),_c('li',[_c('b-button',{staticClass:"coll-btn",on:{"click":function($event){return _vm.selectFilter('Center')}}},[_vm._v(_vm._s(_vm.filter.center || "All Center")+" "),_c('i',{staticClass:"fas fa-chevron-down"})])],1),_c('li',[_c('b-button',{staticClass:"coll-btn",on:{"click":function($event){return _vm.selectFilter('Patient')}}},[_vm._v(_vm._s(_vm.filter.patientType)+" "),_c('i',{staticClass:"fas fa-chevron-down"})])],1)]),_c('b-collapse',{attrs:{"id":"collapse-1"},model:{value:(_vm.showMonthFilter),callback:function ($$v) {_vm.showMonthFilter=$$v},expression:"showMonthFilter"}},[_c('ul',{staticClass:"month-selection-list"},_vm._l((_vm.months),function(item,key){return _c('li',{key:key},[(!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text))?_c('a',{class:item.text == _vm.filter.month ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.selectPeriod('monthly', item.text);
                    _vm.showMonthFilter = false;
                    _vm.getReports();}}},[_vm._v(_vm._s(item.text))]):_c('a',{class:item.value == _vm.filter.quarter ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.selectPeriod('quarterly', item.value);
                    _vm.showMonthFilter = false;
                    _vm.getReports();}}},[_vm._v(_vm._s(item.text))])])}),0),_c('ul',{staticClass:"year-selection-list"},[_c('li',_vm._l((_vm.years),function(year,index){return _c('a',{key:index,class:_vm.filter.year == year ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.year = year;
                    _vm.getReports();}}},[_vm._v(_vm._s(year))])}),0)])]),_c('b-collapse',{attrs:{"id":"collapse-2"},model:{value:(_vm.showCenterFilter),callback:function ($$v) {_vm.showCenterFilter=$$v},expression:"showCenterFilter"}},[_c('ul',{staticClass:"center-selection-list"},[_c('li',[_c('a',{class:_vm.filter.center == '' ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.center = '';
                    _vm.showCenterFilter = false;
                    _vm.getReports();}}},[_vm._v("All")])]),_vm._l((_vm.centers),function(item,key){return _c('li',{key:key},[_c('a',{class:item.short_form == _vm.filter.center ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.center = item.short_form;
                    _vm.showCenterFilter = false;
                    _vm.getReports();}}},[_vm._v(_vm._s(item.short_form))])])})],2)]),_c('b-collapse',{attrs:{"id":"collapse-3"},model:{value:(_vm.showPatientFilter),callback:function ($$v) {_vm.showPatientFilter=$$v},expression:"showPatientFilter"}},[_c('ul',{staticClass:"patient-selection-list"},[_c('li',[_c('a',{class:_vm.filter.patientType == 'Zero Clubfoot' ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.patientType = 'Zero Clubfoot';
                    _vm.showPatientFilter = false;
                    _vm.getReports();}}},[_vm._v("Zero Clubfoot")])]),_c('li',[_c('a',{class:_vm.filter.patientType == 'Physiotherapy' ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.patientType = 'Physiotherapy';
                    _vm.showPatientFilter = false;
                    _vm.getReports();}}},[_vm._v("Physiotherapy")])]),_c('li',[_c('a',{class:_vm.filter.patientType == 'Dental' ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.patientType = 'Dental';
                    _vm.showPatientFilter = false;
                    _vm.getReports();}}},[_vm._v("Dental")])]),_c('li',[_c('a',{class:_vm.filter.patientType == 'Plaster Material' ? 'active' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filter.patientType = 'Plaster Material';
                    _vm.showPatientFilter = false;
                    _vm.getReports();}}},[_vm._v("Plaster Material")])])])])],1)]),_c('div',{staticClass:"target-content"},[_c('h2',{staticClass:"content-title"},[_vm._v("Treatment Phase (TP)")]),_c('ul',_vm._l((_vm.services),function(service,service_index){return _c('li',{key:service_index},[(
                [
                  'Ponseti (Plaster) Pkg',
                  'Ponseti (Plaster)',
                  'Ponseti (Surgery)',
                  'Ponseti (Tenotomy) Pkg',
                  'Ponseti (Tenotomy)',
                  'registration_fee',
                  'therapy_session',
                  'therapy_appointment',
                  'assistive_device',
                  'Dental',
                  'dental_appointment',
                  'Plaster Material'
                ].includes(service.product_type) &&
                  (_vm.checkTargetExists(service.product_type) || _vm.filter.patientType == 'Plaster Material')
              )?_c('div',[_c('div',{staticClass:"l-label"},[_c('label',[_vm._v(_vm._s(_vm.labelUpdate(service.product_type)))])]),_c('div',{staticClass:"r-label"},[_c('span',{staticClass:"achieved"},[_vm._v(_vm._s(_vm.getServiceCount(service.product_type, service.patient_count)))]),_vm._v(" / "),_c('span',{staticClass:"target"},[_vm._v(_vm._s(_vm.getTarget(service.product_type)))])]),_c('b-progress',{staticClass:"mb-3 brd-15 ftb-15 w-100",attrs:{"max":_vm.max,"height":"25px","variant":_vm.calculateProgress(
                    _vm.getServiceCount(
                      service.product_type,
                      service.patient_count
                    ),
                    _vm.getTarget(service.product_type)
                  ) >= 100
                    ? 'success'
                    : 'warning',"precision":0,"show-progress":""}},[_c('b-progress-bar',{attrs:{"value":_vm.calculateProgress(
                      _vm.getServiceCount(
                        service.product_type,
                        service.patient_count
                      ),
                      _vm.getTarget(service.product_type)
                    )}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.calculateProgress( _vm.getServiceCount( service.product_type, service.patient_count ), _vm.getTarget(service.product_type) ))+"%")])])])],1)],1):_vm._e()])}),0),_c('h2',{staticClass:"content-title"},[_vm._v("Follow-up Phase (FP)")]),_c('ul',_vm._l((_vm.services),function(service,service_index){return _c('li',{key:service_index},[(
                [
                  'clubfoot_appointment',
                  'brace Pkg',
                  'brace',
                  'therapy_session_fp',
                  'therapy_appointment_fp',
                  'assistive_device_fp',
                  'Dental_fp',
                  'dental_appointment_fp',
                ].includes(service.product_type) &&
                  (_vm.checkTargetExists(service.product_type) ||
                    _vm.filter.patientType == 'Dental' ||
                    _vm.filter.patientType == 'Physiotherapy')
              )?_c('div',[_c('div',{staticClass:"l-label"},[_c('label',[_vm._v(_vm._s(_vm.labelUpdate(service.product_type)))])]),_c('div',{staticClass:"r-label"},[_c('span',{staticClass:"achieved"},[_vm._v(_vm._s(service.patient_count))]),(_vm.getTarget(service.product_type))?_c('span',[_vm._v("/")]):_vm._e(),(_vm.getTarget(service.product_type))?_c('span',{staticClass:"target"},[_vm._v(_vm._s(_vm.getTarget(service.product_type)))]):_vm._e()]),_c('b-progress',{staticClass:"mb-3 brd-15 ftb-15 w-100",attrs:{"max":_vm.max,"height":"25px","variant":_vm.calculateProgress(
                    service.patient_count,
                    _vm.getTarget(service.product_type)
                  ) >= 100
                    ? 'success'
                    : 'warning',"precision":0,"show-progress":""}},[_c('b-progress-bar',{attrs:{"value":_vm.calculateProgress(
                      service.patient_count,
                      _vm.getTarget(service.product_type)
                    )}},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.calculateProgress( service.patient_count, _vm.getTarget(service.product_type) ))+"%")])])])],1)],1):_vm._e()])}),0)])])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"c-card p-7"},[_c('div',{staticClass:"patient-income-table tableFixHead"},[_c('table',{staticClass:"timeline-tbl"},[_vm._m(0),_vm._l((_vm.table_data),function(data,index){return _c('tbody',{key:index,class:data.expand ? 'expanded-row' : ''},[(!data.expand)?_c('tr',[_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleRowClicked(index, data.date)}}},[_vm._v(" "+_vm._s(data.isToday && _vm.filter.period == "monthly" ? "Today" : _vm.dateFormat(data.date))+" ")]),_c('td',[_vm._v(_vm._s(data.new_patient))]),_c('td',[_vm._v(_vm._s(_vm.numberFormat(data.tp_income)))]),_c('td',[_vm._v(_vm._s(_vm.numberFormat(data.fp_income)))]),_c('td',[_vm._v(_vm._s(_vm.numberFormat(data.tp_income + data.fp_income)))])]):_vm._e(),_vm._l((data.services),function(service,sIndex){return (data.expand && data.services && data.services.length)?_c('tr',{key:sIndex},[(sIndex == 0)?_c('td',{staticStyle:{"vertical-align":"top !important","cursor":"pointer"},attrs:{"rowspan":data.services.length},on:{"click":function($event){return _vm.handleRowClicked(index, data.date)}}},[_c('strong',[_vm._v(_vm._s(data.isToday && _vm.filter.period == "monthly" ? "Today" : _vm.dateFormat(data.date)))])]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.labelUpdate(service.product_type)))]),_c('td',[(
                    [
                      'Ponseti (Plaster)',
                      'Ponseti (Plaster) Pkg',
                      'Ponseti (Surgery)',
                      'Ponseti (Surgery) Pkg',
                      'Ponseti (Tenotomy)',
                      'Ponseti (Tenotomy) Pkg',
                      'registration_fee',
                      'therapy_appointment',
                      'therapy_session',
                      'dental_appointment',
                      'Dental',
                      'Plaster Material'
                    ].includes(service.product_type)
                  )?_c('span',[_vm._v(_vm._s(_vm.numberFormat(service.total_income)))]):_vm._e()]),_c('td',[(
                    ![
                      'Ponseti (Plaster)',
                      'Ponseti (Plaster) Pkg',
                      'Ponseti (Surgery)',
                      'Ponseti (Surgery) Pkg',
                      'Ponseti (Tenotomy)',
                      'Ponseti (Tenotomy) Pkg',
                      'registration_fee',
                      'therapy_appointment',
                      'therapy_session',
                      'dental_appointment',
                      'Dental',
                      'Plaster Material'
                    ].includes(service.product_type)
                  )?_c('span',[_vm._v(_vm._s(_vm.numberFormat(service.total_income)))]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.numberFormat(service.total_income)))])]):_vm._e()}),(data.expand && data.services && data.services.length)?_c('tr',{staticClass:"expanded-total-row"},[_c('td',[_c('ul',[_vm._m(1,true),_c('li',[_vm._v(" "+_vm._s(_vm.filter.period == "monthly" ? "Yesterday" : "Last Month")+" ")])])]),_c('td'),_c('td',[_c('ul',[_c('li',[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(data.tp_income)))])]),_c('li',[_c('span',{class:_vm.calculatePercentage(
                          data.tp_income,
                          data.prev_day.tp_income
                        ) <= 0
                          ? 'down-text'
                          : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                            data.tp_income,
                            data.prev_day.tp_income
                          ) <= 0
                            ? 'fa-chevron-down'
                            : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( data.tp_income, data.prev_day.tp_income ))+"%")])])])]),_c('td',[_c('ul',[_c('li',[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(data.fp_income)))])]),_c('li',[_c('span',{class:_vm.calculatePercentage(
                          data.fp_income,
                          data.prev_day.fp_income
                        ) <= 0
                          ? 'down-text'
                          : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                            data.fp_income,
                            data.prev_day.fp_income
                          ) <= 0
                            ? 'fa-chevron-down'
                            : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( data.fp_income, data.prev_day.fp_income ))+"%")])])])]),_c('td',[_c('ul',[_c('li',[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(data.tp_income + data.fp_income)))])]),_c('li',[_c('span',{class:_vm.calculatePercentage(
                          data.tp_income + data.fp_income,
                          data.prev_day.tp_income + data.prev_day.fp_income
                        ) <= 0
                          ? 'down-text'
                          : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                            data.tp_income + data.fp_income,
                            data.prev_day.tp_income + data.prev_day.fp_income
                          ) <= 0
                            ? 'fa-chevron-down'
                            : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( data.tp_income + data.fp_income, data.prev_day.tp_income + data.prev_day.fp_income ))+"%")])])])])]):_vm._e(),(data.expand && data.services && !data.services.length)?_c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(data.isToday && _vm.filter.period == "monthly" ? "Today" : _vm.dateFormat(data.date)))])]),_vm._m(2,true)]):_vm._e()],2)}),_vm._m(3)],2)]),_c('div',{staticClass:"summary-wrapper"},[_c('table',{staticClass:"summary-tbl"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"16%"}},[_vm._v(" "+_vm._s(_vm.filter.period == "monthly" ? _vm.filter.month + " " + _vm.filter.year : "Q" + _vm.filter.quarter + " " + _vm.filter.year)+" ")]),_c('th',{staticStyle:{"width":"20%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.incomeCalculate("new_patient"))+" ")]),_c('th',{staticStyle:{"width":"22%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.incomeCalculate("tp_income")))+" ")]),_c('th',{staticStyle:{"width":"20%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.incomeCalculate("fp_income")))+" ")]),_c('th',{staticStyle:{"width":"22%","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.numberFormat( _vm.incomeCalculate("tp_income") + _vm.incomeCalculate("fp_income") ))+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"16%"}},[_vm._v(" "+_vm._s(_vm.filter.period == "monthly" ? "Last Month" : "Last Quarter")+" ")]),_c('td',{staticStyle:{"width":"20%","text-align":"center"}}),_c('td',{staticStyle:{"width":"22%","text-align":"center"}},[_c('span',{class:_vm.calculatePercentage(
                      _vm.incomeCalculate('tp_income'),
                      _vm.lastMonthData.tp_income
                    ) <= 0
                      ? 'down-text'
                      : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                        _vm.incomeCalculate('tp_income'),
                        _vm.lastMonthData.tp_income
                      ) <= 0
                        ? 'fa-chevron-down'
                        : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( _vm.incomeCalculate("tp_income"), _vm.lastMonthData.tp_income ))+"%")])]),_c('td',{staticStyle:{"width":"20%","text-align":"center"}},[_c('span',{class:_vm.calculatePercentage(
                      _vm.incomeCalculate('fp_income'),
                      _vm.lastMonthData.fp_income
                    ) <= 0
                      ? 'down-text'
                      : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                        _vm.incomeCalculate('fp_income'),
                        _vm.lastMonthData.fp_income
                      ) <= 0
                        ? 'fa-chevron-down'
                        : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( _vm.incomeCalculate("fp_income"), _vm.lastMonthData.fp_income ))+"%")])]),_c('td',{staticStyle:{"width":"22%","text-align":"right"}},[_c('span',{class:_vm.calculatePercentage(
                      _vm.incomeCalculate('tp_income') +
                        _vm.incomeCalculate('fp_income'),
                      _vm.lastMonthData.fp_income + _vm.lastMonthData.tp_income
                    ) <= 0
                      ? 'down-text'
                      : 'up-text'},[_c('i',{staticClass:"fas",class:_vm.calculatePercentage(
                        _vm.incomeCalculate('tp_income') +
                          _vm.incomeCalculate('fp_income'),
                        _vm.lastMonthData.fp_income + _vm.lastMonthData.tp_income
                      ) <= 0
                        ? 'fa-chevron-down'
                        : 'fa-chevron-up'}),_vm._v(" "+_vm._s(_vm.calculatePercentage( _vm.incomeCalculate("tp_income") + _vm.incomeCalculate("fp_income"), _vm.lastMonthData.fp_income + _vm.lastMonthData.tp_income ))+" %")])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Timeline")]),_c('th',[_vm._v("New Patient")]),_c('th',[_vm._v("TP Income")]),_c('th',[_vm._v("FP Income")]),_c('th',[_vm._v("Total Income")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Total")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"4"}},[_c('strong',[_vm._v("Data not available")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tfoot',[_c('tr',[_c('td')])])
}]

export { render, staticRenderFns }